import styled, { css } from 'styled-components'
import Anchor from '../../../../components/ui/anchor'
import { device } from '../../../../theme'

export const SectionWrap = styled.div`
  padding-bottom: 80px;
  padding-top: ${(props) => (props.padding ? '80px' : '0')};
  background: ${(props) =>
    props.split
      ? `linear-gradient(
            180deg,
            ${props.theme.colors.white} 0%,
            ${props.theme.colors.white} 298px,
            ${props.theme.colors.background} 299px,
            ${props.theme.colors.background} 100%
        )`
      : `${props.theme.colors.background}`};

  @media ${device.medium} {
    ${(props) =>
      props.split &&
      css`
        background-image: linear-gradient(
          180deg,
          ${props.theme.colors.white} 0%,
          ${props.theme.colors.white} 359px,
          ${props.theme.colors.background} 360px,
          ${props.theme.colors.background} 100%
        );
      `}
  }

  @media ${device.small} {
    padding-bottom: 60px;
    padding-top: ${(props) => (props.padding ? '60px' : '0')};

    ${(props) =>
      props.split &&
      css`
        background-image: linear-gradient(
          180deg,
          ${props.theme.colors.white} 0%,
          ${props.theme.colors.white} 331px,
          ${props.theme.colors.background} 332px,
          ${props.theme.colors.background} 100%
        );
      `}
  }

  .box-item {
    margin-top: 30px;
  }

  a {
    font-family: ${(props) => props.theme.fontFamily.heading};
  }
`

export const ReadMore = styled(Anchor)`
  position: absolute;
  right: 15px;
  top: -4px;
  display: flex;
  align-items: center;
  font-size: 16px;

  &:not(:hover) {
    color: ${(props) => props.theme.colors.textDark};
  }

  svg {
    width: 48px;
    height: 48px;
    padding: 8px;
    color: ${(props) => props.theme.colors.white};
    border-radius: 48px;
    background-color: ${(props) => props.theme.colors.theme};
    margin-left: 8px;
  }

  span {
    margin-left: 4px;
  }

  @media ${device.xsmall} {
    top: 0;

    svg {
      width: 36px;
      height: 36px;
      border-radius: 36px;
      padding: 0;
    }
  }

  @media ${device.xxsmall} {
    span {
      display: none;
    }
  }
`
