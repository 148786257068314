import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { IoIosArrowRoundForward } from 'react-icons/io'
import SectionTitle from '../../../../components/ui/section-title'
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import { SectionWrap, ReadMore } from './section.style'
import BoxLargeImage from '../../../../components/box-large-image/layout-two'
import { slugify } from '../../../../utils/utilFunctions'

const BoxSection = ({
  path,
  split,
  padding,
  title,
  items,
  buttontext,
  detailButtonText,
  type,
  limit,
  filter,
  linkStyle,
  sectionTitleStyle,
  boxStyles,
  hideAll,
}) => {
  const NewsData = useStaticQuery(graphql`
    query LatestNewsQuery {
      newsArticles: allContentfulNieuwsbericht(
        sort: { fields: date, order: DESC }
        filter: {
          title: { ne: null }
          slug: { regex: "/^(?!untitled-entry).+/" }
          date: { ne: null }
        }
      ) {
        edges {
          node {
            id
            title
            date(locale: "nl_NL", formatString: "DD MMMM YYYY")
            slug
            image {
              fluid(maxWidth: 260, cropFocus: CENTER) {
                ...GatsbyContentfulFluid_withWebp
              }
              title
              description
            }
            themes {
              id
              title
            }
          }
        }
      }

      articles: allContentfulPublicatie(
        sort: { fields: date, order: DESC }
        filter: {
          title: { ne: null }
          slug: { regex: "/^(?!untitled-entry).+/" }
          date: { ne: null }
        }
      ) {
        edges {
          node {
            id
            title
            date(locale: "nl_NL", formatString: "DD MMMM YYYY")
            slug
            image {
              fluid(maxWidth: 260, cropFocus: CENTER) {
                ...GatsbyContentfulFluid_withWebp
              }
              title
              description
            }
            themes {
              id
              title
            }
          }
        }
      }

      subsidy: allContentfulSubsidie(
        sort: { fields: date, order: DESC }
        filter: {
          title: { ne: null }
          slug: { regex: "/^(?!untitled-entry).+/" }
          date: { ne: null }
        }
      ) {
        edges {
          node {
            id
            title
            date(locale: "nl_NL", formatString: "DD MMMM YYYY")
            slug
            image {
              fluid(maxWidth: 260, cropFocus: CENTER) {
                ...GatsbyContentfulFluid_withWebp
              }
              title
              description
            }
            themes {
              id
              title
            }
          }
        }
      }

      subthemes: allContentfulOnderwerp(
        filter: {
          title: { ne: null }
          slug: { regex: "/^(?!untitled-entry).+/" }
        }
      ) {
        edges {
          node {
            id
            title
            image {
              fluid(maxWidth: 260, cropFocus: CENTER) {
                ...GatsbyContentfulFluid_withWebp
              }
              title
              description
            }
            themes {
              id
              title
            }
          }
        }
      }
    }
  `)

  const getType = () => {
    switch (type) {
      case 'article':
        return NewsData.articles.edges.map((edge) => edge.node)
      case 'subtheme':
        return NewsData.subthemes.edges.map((edge) => edge.node)
      case 'subsidy':
        return NewsData.subsidy.edges.map((edge) => edge.node)
      default:
        return NewsData.newsArticles.edges.map((edge) => edge.node)
    }
  }

  const itemList = () => {
    if (filter) {
      return getType()
        .map((item) => {
          return item.themes &&
            item.themes.filter((theme) => theme.id === filter).length
            ? item
            : false
        })
        .filter((result) => result)
        .slice(0, limit)
    } else if (items) {
      return items
    } else {
      return getType().slice(0, limit)
    }
  }

  const renderThemeString = (themes) => {
    if (themes) {
      if (typeof themes === 'string' || themes instanceof String) {
        return themes
      } else {
        themes.map((theme) => <span>{theme.title}</span>)
      }
    }
  }

  return (
    <>
      {itemList().length > 0 && (
        <SectionWrap padding={padding} split={split} className="container-xl">
          <Container>
            <Row>
              <Col xl={12}>
                <SectionTitle
                  {...sectionTitleStyle}
                  title={title}
                  align="left"
                />

                {!hideAll && (
                  <ReadMore
                    path={path}
                    title={`Ga naar de ${buttontext} overzichtpagina`}
                    aria-label={`Ga naar de ${buttontext} overzichtpagina`}
                    {...linkStyle}
                  >
                    Meer <span>{buttontext}</span>
                    <IoIosArrowRoundForward
                      className="icon"
                      role="img"
                      title={`Meer ${buttontext}`}
                    />
                  </ReadMore>
                )}
              </Col>
            </Row>
            <Row>
              {itemList().map((item) => {
                const imageAltText =
                  item.image?.description ?? item.image?.title

                return (
                  <Col
                    lg={3}
                    md={6}
                    className="box-item"
                    key={`box-image-${item.id}`}
                  >
                    <BoxLargeImage
                      {...boxStyles}
                      category={
                        item.date ||
                        renderThemeString(item.themes || item.theme)
                      }
                      imageSrc={item.image}
                      imageAltText={
                        imageAltText !== '' || !imageAltText
                          ? imageAltText
                          : item.title
                      }
                      title={item.title}
                      path={item.slug ? `${path}/${slugify(item.slug)}` : ''}
                      btnText={detailButtonText}
                    />
                  </Col>
                )
              })}
            </Row>
          </Container>
        </SectionWrap>
      )}
    </>
  )
}

BoxSection.propTypes = {
  path: PropTypes.string,
  split: PropTypes.bool,
  padding: PropTypes.bool,
  title: PropTypes.string,
  items: PropTypes.array,
  buttontext: PropTypes.string,
  detailButtonText: PropTypes.string,
  sectionTitleStyle: PropTypes.object,
  headingStyle: PropTypes.object,
  linkStyle: PropTypes.object,
  boxStyles: PropTypes.object,
  hideAll: PropTypes.bool,
  limit: PropTypes.number,
  filter: PropTypes.string,
  type: PropTypes.oneOf(['subsidy', 'news', 'subtheme', 'article']),
}

BoxSection.defaultProps = {
  path: '/actueel/nieuws',
  split: false,
  padding: true,
  title: 'Laatste nieuws',
  buttontext: 'nieuws',
  detailButtonText: 'Bekijk artikel',
  hideAll: false,
  limit: 4,
  type: 'news',
  sectionTitleStyle: {
    responsive: {
      medium: {
        mb: '10px',
      },
    },
  },
  boxStyles: {
    headingStyle: {
      color: '#000',
    },
  },
  sectionBottomStyle: {
    mt: '40px',
    align: 'center',
  },
}

export default BoxSection
